@import "~bootstrap/less/bootstrap";
@import "generic";

.b2b-login {
  margin-top: 30px;
  padding: 25px;
  background-color: @blueGreyBackground;
  border: 1px solid #c6c5c5;

  form {
    margin-top: 5px;
  }

  label {
    font-size: 16px;
  }

  .form-control {
    border-width: 2px;
  }

  input[type="submit"] {
    width: 100%;
    font-size: 16px;
    display: block;
    margin: 15px auto;
  }
}

.b2b-logout {
  margin-bottom: 1em;
}

.b2b-login-page {
  .branding-text-container {
    display: none;
  }
  .branding-logo-container {
    width: 100%;

    .branding-logo {
      display: block;
      text-align: center;
      margin-top: 2em;

      a {
        display: block;
        text-align: center;

        img {
          margin: 0 auto;
        }
      }
    }
  }
}
